const DATE_OPTIONS = {
    TODAY: "Сегодня",
    YESTERDAY: "Вчера",
    THIS_WEEK: "Текущая неделя",
    LAST_7_DAYS: "Последние 7 дней",
    THIS_MONTH: "Текущий месяц",
    PREVIOUS_MONTH: "Прошлый месяц",
    LAST_30_DAYS: "Последние 30 дней",
    THIS_YEAR: "Текущий год",
    ALL_TIME: "Все время",
    RANGE_OF_DAYS: "Период по датам",
    TIME_RANGE: "Период по времени",
};

const DEFAULT_COLUMN_WIDTH = 100; // 100px
const DEFAULT_REPORT_OPTION_KEY = "report_options";
const DEFAULT_REPORT_COLUMN_WIDTHS_KEY = "report_column_widths";

export { DATE_OPTIONS, DEFAULT_COLUMN_WIDTH, DEFAULT_REPORT_OPTION_KEY, DEFAULT_REPORT_COLUMN_WIDTHS_KEY };
