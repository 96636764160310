import { useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CustomDatePicker = ({ selectedDate, onValidDateTime, enableManualTime, timeMode = "start" }) => {
    const [date, setDate] = useState(selectedDate ? new Date(selectedDate) : null);
    const [manualTime, setManualTime] = useState(() => {
        if (selectedDate) {
            const dateObj = new Date(selectedDate);
            const hours = String(dateObj.getHours()).padStart(2, "0");
            const minutes = String(dateObj.getMinutes()).padStart(2, "0");
            return `${hours}:${minutes}`;
        }
        return "__:__";
    });
    const inputRef = useRef(null);

    // Функция для обработки и передачи выбранной даты + времени
    const handleDateTimeChange = (newDate, newTime) => {
        if (!newDate) return;

        let selectedDateTime = new Date(newDate);

        if (!enableManualTime) {
            selectedDateTime.setHours(timeMode === "start" ? 0 : 23);
            selectedDateTime.setMinutes(timeMode === "start" ? 0 : 59);
            selectedDateTime.setSeconds(timeMode === "start" ? 0 : 59);
            selectedDateTime.setMilliseconds(timeMode === "start" ? 0 : 999);

            onValidDateTime(selectedDateTime);
        } else if (/^[0-2][0-9]:[0-5][0-9]$/.test(newTime)) {
            const [hours, minutes] = newTime.split(":").map(Number);
            selectedDateTime.setHours(hours);
            selectedDateTime.setMinutes(minutes);
            selectedDateTime.setSeconds(0);
            selectedDateTime.setMilliseconds(0);

            onValidDateTime(selectedDateTime);
        }
    };

    const handleTimeChange = (event) => {
        let input = event.target;
        let cursorPos = input.selectionStart; // Получаем текущую позицию курсора

        let value = input.value.replace(/[^0-9]/g, ""); // Убираем все символы, кроме цифр

        // Ограничиваем ввод только 4 символами (часы + минуты)
        if (value.length > 4) {
            value = value.slice(0, 4);
        }

        // Разбиваем часы и минуты
        let hours = value.slice(0, 2);
        let minutes = value.slice(2, 4);

        // Преобразуем в числа для валидации
        let hoursNum = parseInt(hours, 10);
        let minutesNum = parseInt(minutes, 10);

        // Ограничиваем диапазон часов (00-23)
        if (!isNaN(hoursNum)) {
            if (hoursNum > 23) hours = "23";
            if (hoursNum < 0) hours = "00";
        }

        // Ограничиваем диапазон минут (00-59)
        if (!isNaN(minutesNum)) {
            if (minutesNum > 59) minutes = "59";
            if (minutesNum < 0) minutes = "00";
        }

        // Формируем финальный вид HH:MM с подстановкой `_` для отсутствующих символов
        let formattedTime = (hours.padEnd(2, "_") || "__") + ":" + (minutes.padEnd(2, "_") || "__");

        setManualTime(formattedTime);

        // Вызываем `handleDateTimeChange`, только если уже выбрана дата
        if (date) {
            handleDateTimeChange(date, formattedTime);
        }

        // Восстанавливаем позицию курсора после обновления состояния
        setTimeout(() => {
            if (inputRef.current) {
                // Если удалили символ перед `:`, курсор остаётся перед `:`
                if (cursorPos === 3 && event.nativeEvent.inputType === "deleteContentBackward") {
                    cursorPos = 2;
                } else if (cursorPos === 3 && value.length === 3 && event.nativeEvent.inputType === "insertText") {
                    cursorPos++;
                }

                inputRef.current.setSelectionRange(cursorPos, cursorPos);
            }
        }, 0);
    };

    const handleDateChange = (newDate) => {
        setDate(newDate);
        handleDateTimeChange(newDate, manualTime);
    };

    return (
        <div className={`custom-date-picker ${enableManualTime ? "with-time" : ""}`}>
            <DatePicker selected={date} onChange={handleDateChange} dateFormat="yyyy-MM-dd" />
            {enableManualTime && (
                <input
                    ref={inputRef}
                    type="text"
                    value={manualTime}
                    onChange={handleTimeChange}
                    className="time-input"
                    placeholder="__:__"
                />
            )}
        </div>
    );
};

export default CustomDatePicker;
