import React, { useEffect, useMemo, useState } from "react";
import { useTable, useSortBy, useBlockLayout, useResizeColumns } from "react-table";
import ArrowLeft from "../../components/icons/ArrowLeft";
import { formatDateTime } from "../../common/commonUtil";
import { DEFAULT_COLUMN_WIDTH } from "../../constants/report";

function Table({ data, columns, sortBy, order, updateOptions, isLoading, saveColumnWidths }) {
    const [columnWidths, setColumnWidths] = useState({});
    const [isSaveColumnWidths, setIsSaveColumnWidths] = useState(false);

    const { defaultColumnStyle, visibleColumns } = useMemo(() => {
        const style = {
            minWidth: 30,
            maxWidth: 1000,
        };

        const widths = {};
        columns.forEach((col) => {
            if (col.width) {
                widths[col.accessor] = col.width;
            }
        });

        setColumnWidths(widths);

        const visibleColumns = columns.filter((col) => !col.isHidden);

        const navbar = document.querySelector(".navbar");
        const container = document.querySelector(".page-wrapper .container");
        const navbarWidth = navbar.clientWidth;
        container.style.width = navbarWidth + 48 + "px";
        const columnWidth = navbarWidth / visibleColumns.length;

        style.width = columnWidth < DEFAULT_COLUMN_WIDTH ? DEFAULT_COLUMN_WIDTH : columnWidth;
        return { defaultColumnStyle: style, visibleColumns };
    }, [columns, window.clientWidth]);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns: visibleColumns,
            data,
            defaultColumn: defaultColumnStyle,
        },
        useBlockLayout,
        useResizeColumns,
        useSortBy // Добавляем сортировку
    );

    const handlerSortingClick = (name) => {
        updateOptions({ sort_by: name, sort_order: order === "asc" ? "desc" : "asc" });
    };

    const handleColumnResize = (column) => {
        return (e) => {
            e.stopPropagation(); // Не даём ресайзу триггерить сортировку

            const newWidth = column.totalWidth; // Текущая ширина после ресайза
            const accessor = column.id; // Получаем accessor колонки

            // Обновляем state и localStorage
            setColumnWidths((prevWidths) => {
                const updatedWidths = { ...prevWidths, [accessor]: newWidth };
                return updatedWidths;
            });
        };
    };

    const onMouseUpResize = () => {
        setIsSaveColumnWidths(true);
    };

    useEffect(() => {
        if (isSaveColumnWidths) {
            saveColumnWidths(columnWidths);
            setIsSaveColumnWidths(false);
        }
    }, [columnWidths, isSaveColumnWidths]);

    return (
        <div className="react-table-wrapper">
            <div className="perfect-scrollbar">
                <div {...getTableProps()} className={`table ${isLoading ? "loading-table" : ""}`}>
                    <div>
                        {headerGroups.map((headerGroup) => {
                            const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
                            return (
                                <div key={key} {...headerGroupProps} className="tr">
                                    {headerGroup.headers.map((column) => {
                                        const { key, ...headerProps } = column.getHeaderProps(
                                            column.getSortByToggleProps()
                                        );
                                        const isSorted = column.isSorted;
                                        const sortClass = isSorted
                                            ? column.isSortedDesc
                                                ? "sorted-desc"
                                                : "sorted-asc"
                                            : "";

                                        return (
                                            <div
                                                key={key}
                                                {...headerProps}
                                                className={`th ${sortClass}`}
                                                onClick={() => handlerSortingClick(column.id)}
                                            >
                                                {column.render("Header")}
                                                {sortBy === column.id && (
                                                    <span className={`sort-icon ${order}`}>
                                                        <ArrowLeft />
                                                    </span>
                                                )}
                                                <div
                                                    {...column.getResizerProps()}
                                                    onClick={handleColumnResize(column)}
                                                    onMouseUp={onMouseUpResize}
                                                    className={`resizer ${column.isResizing ? "isResizing" : ""}`}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>

                    <div {...getTableBodyProps()}>
                        {rows.map((row) => {
                            prepareRow(row);
                            const { key, ...rowProps } = row.getRowProps();

                            return (
                                <div key={key} {...rowProps} className="tr body-tr">
                                    {row.cells.map((cell) => {
                                        const { key, ...cellProps } = cell.getCellProps();
                                        const value = cell.render("Cell");
                                        return (
                                            <div key={key} {...cellProps} className="td">
                                                {key.includes("install_time")
                                                    ? formatDateTime(value.props.value)
                                                    : value}
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Table;
