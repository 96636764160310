import { useContext } from "react";
import SelectWithLabel from "../../components/UI/SelectWithLabel";
import IphoneSwitch from "../../components/UI/IphoneSwitch";
import Loader from "../../components/UI/Loader";
import {
    BUTTONS_TYPE,
    DISPLAY_PLAY_BTN_OPTIONS,
    DOWNLOAD_OPTIONS,
    REDIRECTS_OPTIONS,
    SYSTEM_PUSH_OPTIONS,
    TYPE_PAGE_OPTIONS,
} from "../../constants/pwa";
import { UserContext, UserGroup } from "../../context/User";

const Settings = ({ data, updateData, templates }) => {
    const user = useContext(UserContext);
    if (!templates) return <Loader />;

    const updateSettings = (key, value) => {
        updateData({ keys: [key], value });
    };
    return (
        <div className="settings-pwa">
            {!data?.default_pwa_template && (
                <div className="four-fields">
                    <SelectWithLabel
                        options={templates}
                        value={data.template}
                        label="Шаблон"
                        fieldName="template"
                        onChange={updateSettings}
                    />
                </div>
            )}

            {user?.hasPermission(UserGroup.DEVELOPER) && (
                <div className="four-fields">
                    <SelectWithLabel
                        options={SYSTEM_PUSH_OPTIONS}
                        value={data.push_system}
                        label="Система пушей"
                        fieldName="push_system"
                        onChange={updateSettings}
                    />
                </div>
            )}
            <div className="four-fields">
                <SelectWithLabel
                    options={BUTTONS_TYPE}
                    value={data.installBtnVersion}
                    label={`Тип инициализации кнопки "Установить"`}
                    fieldName="installBtnVersion"
                    onChange={updateSettings}
                />
            </div>
            <div className="four-fields">
                <SelectWithLabel
                    options={REDIRECTS_OPTIONS}
                    value={data.redirectVersion}
                    label="Тип редиректа"
                    fieldName="redirectVersion"
                    onChange={updateSettings}
                />
            </div>
            <div
                className="tip"
                style={{ marginTop: "2px", minHeight: "26px" }}
            >
                {REDIRECTS_OPTIONS[data.redirectVersion - 1]?.desc}
            </div>
            <div className="four-fields">
                <SelectWithLabel
                    label="Тип страницы"
                    value={data.typePage}
                    fieldName="typePage"
                    onChange={updateSettings}
                    options={Object.values(TYPE_PAGE_OPTIONS).map((value) => value)}
                />
                {data.typePage === TYPE_PAGE_OPTIONS.WEB.value && (
                    <div className="label-with-checkbox" style={{ marginTop: "20px" }}>
                        <label>Показывать верхнее меню</label>
                        <IphoneSwitch
                            isChecked={data.showHeaderMenu}
                            fieldName="showHeaderMenu"
                            onChange={updateSettings}
                        />
                    </div>
                )}
            </div>
            {user?.hasPermission(UserGroup.DEVELOPER) && (
                <div className="four-fields">
                    <SelectWithLabel
                        label={`Отображение кнопки "Играть"`}
                        value={data.display_btn_play}
                        fieldName="display_btn_play"
                        onChange={updateSettings}
                        options={DISPLAY_PLAY_BTN_OPTIONS}
                    />
                </div>
            )}
            <div className="four-fields">
                <SelectWithLabel
                    label="Тип установки"
                    value={data.downloadVersion}
                    fieldName="downloadVersion"
                    onChange={updateSettings}
                    options={DOWNLOAD_OPTIONS}
                />
            </div>
            <div
                className="tip"
                style={{ marginTop: "2px", minHeight: "12px" }}
            >
                {data.downloadVersion && DOWNLOAD_OPTIONS.find(({value}) => value === data.downloadVersion)?.desc || ""}
            </div>
        </div>
    );
};

export default Settings;
